import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { deleteUserAccountAction, getUserDetailsAction } from "../../actions/userActions";
import { GetLocalStorage } from "../../services/SMILocalStorage";
import { logoutAction } from "../../actions/authActions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { USER_ACCOUNT_DELETED_SUCCESSFULLY } from "../../helper/constants";

const UserAccountDeleteModal = ({
  modalShown,
  onModelClose,
  onDeleteConfirm,
  headerTitle = "Delete record(s)",
  message,
  getUserDetailsAction,
  deleteUserAccountAction,
  logoutAction
}) => {

  const sessionUser = GetLocalStorage("smi-user");
  let history = useHistory();

  const [userDetail, setUserDetail] = useState({});
  const [showSkillsLinkedWarningPopup, setShowSkillsLinkedWarningPopup] = useState(false);
  const [showDeleteAccountConfirmationModal, setShowDeleteAccountConfirmationModal] = useState(false);

  useEffect(() => {
    fetchUserDetail();
  }, [modalShown]);

  const fetchUserDetail = async () => {
    getUserDetailsAction(sessionUser?.user?._id).then((data) => {
      if (data?.status === 200) {
        setUserDetail(data.data);
        if (Object.keys(data.data).length > 0) {
          const { amazonActivated, googleActivated, samsungActivated } = data.data;
          if (amazonActivated || googleActivated || samsungActivated) {
            setShowSkillsLinkedWarningPopup(true);
            setShowDeleteAccountConfirmationModal(false);
          }
          else if (!amazonActivated && !googleActivated && !samsungActivated) {
            setShowSkillsLinkedWarningPopup(false);
            setShowDeleteAccountConfirmationModal(true);
          }
        }
      }
    });
  };

  const handleDeleteAccount = () => {
    deleteUserAccountAction(sessionUser?.user?._id).then((deleteUserRes) => {
      if (deleteUserRes?.status === 200) {
        toast.success(USER_ACCOUNT_DELETED_SUCCESSFULLY);
        logoutAction();
        history.push("/client-login")
      }
      else {
        toast.error(deleteUserRes?.message)
      }
    });
  };

  const getVoiceSkillsMessage = () => {
    if (Object.keys(userDetail).length > 0) {
      const { amazonActivated, googleActivated, samsungActivated } = userDetail;
      let services = [];

      if (amazonActivated) services.push('Alexa');
      if (googleActivated) services.push('Google Assistant');
      if (samsungActivated) services.push('Samsung SmartThings');

      let servicesMessage = '';

      if (services.length === 1) {
        servicesMessage = ` with your ${services[0]} account`;
      } else if (services.length === 2) {
        servicesMessage = ` with your ${services[0]} and ${services[1]} accounts`;
      } else if (services.length > 2) {
        servicesMessage = ` with your ${services.slice(0, -1).join(', ')}, and ${services[services.length - 1]} accounts`;
      }

      return `Please unlink and deactivate the voice skill(s)${servicesMessage} before attempting to delete your account.`;
    }
    return '';
  };

  return (
    <>
      {showSkillsLinkedWarningPopup &&
        <Modal
          show={showSkillsLinkedWarningPopup}
          onHide={onModelClose}
          backdrop="static">
          <Modal.Body>
            {getVoiceSkillsMessage()}
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="btn btn-primary px-3 py-2"
              onClick={onModelClose}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>}
      {showDeleteAccountConfirmationModal &&
        <Modal
          show={showDeleteAccountConfirmationModal}
          onHide={onModelClose}
          backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Delete my account
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-danger font-weight-bold mb-3">
              <i className="flaticon-warning-sign pr-2"></i>
              Deleting your account will:
            </div>
            <div className="ml-3">
              <div>
                - Remove your associated devices and spaces
              </div>
              <div>
                - Terminate your Voice AI access
              </div>
              <div>
                - Require you to factory reset the devices
              </div>
              <div>
                - Require a new account if you decide to come back.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="btn btn-primary px-3 py-2"
              onClick={onModelClose}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="btn btn-danger px-3 py-2"
              onClick={() => {

                handleDeleteAccount();
              }}
            >
              Delete my account
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </>
  )
};

export default connect('', {
  getUserDetailsAction,
  deleteUserAccountAction,
  logoutAction
})(UserAccountDeleteModal);