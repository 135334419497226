document.addEventListener("DOMContentLoaded", function () {

    // Extract the si_mid value from document.cookie
    const getCookieValue = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const mid = getCookieValue("si_mid");

    fetch(`${process.env.REACT_APP_SERVER_URL}default/get-assets?mid=${mid}`)
        .then((response) => response.json())
        .then((data) => {
            if (data?.data) {
                const { appName, favicon } = data?.data;
                const faviconLink = document.querySelector('link[rel="shortcut icon"]') || document.createElement('link');
                faviconLink.type = 'image/x-icon';
                faviconLink.rel = 'shortcut icon';

                faviconLink.href = `${process.env.REACT_APP_PUBLIC_URL}public/images/${favicon}`;
                document.head.appendChild(faviconLink);
                document.title = appName;
            }
        })

});
